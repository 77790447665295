import { Button, TextField } from '@finpay-development/shared-components';
import { Grid, MenuItem, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getClientFacilities } from '../../../implementation-specialist/state/facility/implementation-facility-thunk';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { patientService } from '../../../patient/services/patient-service';
import { apiStatus, PfrEstimator, EstimateRequestConfig } from '../../models/estimator';
import { clearEstimator, clearGetEstimateStatus, clearSaveEstimatorStatus, setNewEstimate } from '../../state/estimator-slice';
import { clearPatientIocStatus, setPatient } from '../../state/vob-patient-slice';
import { callNewEstimateGet, callNewEstimatePost, callNewEstimatePut, getSavedEstimates } from '../../state/estimator-thunk';
import { createPatientIoc, getVobPatient } from '../../state/vob-patient-thunk';
import { getVob } from '../../state/vob-thunk';
import { EstCompletePatientRecordModal } from './estimator-complete-patient-record-modal';
import { Utils } from "../../../shared/utils";
import { checkPermissions } from '../../../security/components/access-control';
import { RolePageNames } from '../../../security/model/role-page-names';
import { getInstanceOfCare } from '../../../patient/state/patient-thunk';
import { admissionsAdvisorUtils } from '../../../admissions-advisor/utils/admission-advisor-utils';
import { IocConfig, patientIocResult } from '../../../admissions-advisor/models/patient';
import { typeOfCare } from '../../../shared/model/type-of-care';
import { setSelectedEncounter } from '../../../patient/state/patient-slice';
import { Estimate, CrossoverSelectionEnum, QuoteMethod, Id, EstimateSummary } from '@finpay/estimation-types';

interface estimateConfig {
  paramId: number,
  clientId: number
}

export function NewEstFooter() {
  const navigate = useNavigate();
  const [completePatientRecordModalOpen, setCompletePatientRecordModalOpen] = useState(false);
  const [isEstimateSelected, setIsEstimateSelected] = useState(false);
  const [isCreateNewEstimate, setIsCreateNewEstimate] = useState(false);
  const [savedEstimate, setSavedEstimate] = useState<PfrEstimator>();
  const [filteredEstimates, setFilteredEstimates] = useState<PfrEstimator[]>([]);
  const [savedEncounter, setSavedEncounter] = useState<patientIocResult>();
  const dispatch = useDispatch<AppDispatch>();
  const paramId: number = -2;

  const state = {
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
    ),
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    fetchedEstimateState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.fetchedEstimate
    ),
    fetchedEstimateLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.isLoadingGetEstimate
    ),
    fetchedEstimateStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.getEstimateStatus
    ),
    savedEstimatesLoadingState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.isLoadingSaveEstimates
    ),
    savedEstimatesStatusState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.isLoading.saveEstimateStatus
    ),
    savedEstimationsState: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.estimatorContext.savedEstimations
    ),
    selectedEncounterWorkflow: useSelector((state: RootState) => state.admissionsAdvisorContext.vobPatientContext.patient?.selectedIOC?.workflow),
    userProfile: useSelector(
      (state: RootState) =>
      state.userContext.userProfile
    ),
    allClients: useSelector(
      (state: RootState) =>
          state.implementationContext?.implementationSpecialistClient
              .allClientsWithFacillities
    ),
    selectedFinPassPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext.vobPatientContext.selectedFinPassPatient
    ),
    newEstimate: useSelector(
      (state: RootState) => {
        return state?.admissionsAdvisorContext.estimatorContext.newEstimate
      }
    )
  };
  const {
    vobPatientState,
    vobState,
    fetchedEstimateState,
    fetchedEstimateLoadingState,
    fetchedEstimateStatusState,
    savedEstimatesLoadingState,
    savedEstimatesStatusState,
    savedEstimationsState,
    selectedEncounterWorkflow,
    userProfile,
    allClients,
    selectedFinPassPatient,
    newEstimate
  } = state;

  useEffect(() => {
    const config: estimateConfig = getEstimateConfig();
    console.log('config ', config);
    dispatch(getSavedEstimates(config));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEstimate]);

  useEffect(() => {
    const getSavedIOCEstimateName = async (estimates: PfrEstimator[]) => {
      console.log('estimates get name ', estimates)
      if(newEstimate?.patientEncounterId!) {
        console.log(' have encounter')
        const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId: vobPatientState.patient.fpPatientId!, encounterId: newEstimate?.patientEncounterId!});
        const iocEstimateId = instanceOfCare.entity.pfrEstimateId!;
        const matchingEstimate = estimates.find((estimate) => estimate.pfrEstimateId === iocEstimateId);
        console.log('matching ioc? ', matchingEstimate)
        setSavedEncounter(instanceOfCare.entity);
        setSavedEstimate(matchingEstimate);
      }
    };

    console.log('savedEstimationsState ', savedEstimationsState);
    // TODO: Discuss Saved Estimate Variables for New Estimate Shape
    if(savedEstimationsState.length > 0) {
      let filteredEstimations: PfrEstimator[] = [];
      if(vobState?.vobId) {
        filteredEstimations = savedEstimationsState.filter(
          (estimation) =>
            estimation.vobId === vobState.vobId
        );
        console.log('filteredEstimations ', filteredEstimations);
        setFilteredEstimates(filteredEstimations);
        getSavedIOCEstimateName(filteredEstimations);
      }   
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedEstimationsState]);

  useEffect(() => {
    // TODO: Discuss loading States for New Estimate Shape
    if (!savedEstimatesLoadingState && savedEstimatesStatusState === apiStatus.success) {
      // estimate has been saved - reload saved estimates
      const config: estimateConfig = getEstimateConfig();
      dispatch(clearSaveEstimatorStatus());
      dispatch(getSavedEstimates(config));
    }
    if (isEstimateSelected && !fetchedEstimateLoadingState && fetchedEstimateStatusState === apiStatus.success) {
      // load new estimate from dropdown
      const patientConfig: any = {
        paramId: paramId,
        patientId: fetchedEstimateState.advisorPatientId
      }
      dispatch(getVobPatient(patientConfig));
      const vobConfig: any = {
        paramId: paramId,
        vobId: fetchedEstimateState.vobId
      }
      dispatch(getVob(vobConfig));
      const estimatorStateCopy: any = {...fetchedEstimateState.estimateBody};
      if (estimatorStateCopy) {
        estimatorStateCopy.estimateId = fetchedEstimateState?.pfrEstimateId;
        dispatch(clearEstimator());
        const currentClientId = estimatorStateCopy.client?.clientId || estimatorStateCopy.clientId;
        if (currentClientId) {
          dispatch(getClientFacilities(currentClientId));
        }
        dispatch(setNewEstimate(estimatorStateCopy));
      }
      dispatch(clearGetEstimateStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedEstimatesLoadingState, fetchedEstimateLoadingState]);

  const handlePatientModalSubmit = (estimate:Estimate) => {
    // save estimate
    saveCurrentEstimate(estimate, false, true);
    setCompletePatientRecordModalOpen(false);
  };

  const handlePatientModalCancel = () => {
    setCompletePatientRecordModalOpen(false);
  };

  const getEstimateConfig = (): estimateConfig => {
    const config: estimateConfig = {
      paramId: paramId,
      clientId: vobState.client?.clientId!
    }
    console.log('config ', config)
    return config;
  };

  const handleSavedEstimationChange = async (e: Event) => {
    const estimationId = +(e.target as HTMLInputElement).value;
    console.log('estimationId ', estimationId)
    setIsEstimateSelected(true);
    const config: EstimateRequestConfig = {
      paramId: paramId,
      estimationId: estimationId
    }
    const estimateResponse = await dispatch(callNewEstimateGet(config));
    if(estimateResponse?.payload?.patientEncounterId) {
      let vobPatientStateCopy = Utils.deepClone(vobPatientState);
      const instanceOfCare = await patientService.getPatientInstanceOfCare({patientId: vobPatientState.patient.fpPatientId!, encounterId: estimateResponse?.payload.estimateBody.finPay?.patientEncounterId!});
      vobPatientStateCopy.patient.selectedIOC = instanceOfCare.entity;
      dispatch(setPatient(vobPatientStateCopy.patient));
    }
  };

  const saveCurrentEstimate = async (estimatorStateCopy?:Estimate | null, createNew?: boolean, ignoreIOCSave?: boolean) => {
    let copyOfEstimateState: any = estimatorStateCopy;
    console.log('copyOfEstimateState ', copyOfEstimateState);
    if (!estimatorStateCopy) {
      copyOfEstimateState = newEstimate;
    }
    console.log('copyOfEstimateState 2', copyOfEstimateState)
    const originalEstimate = filteredEstimates.find(estimation => estimation.pfrEstimateId === copyOfEstimateState?.estimateId);
    const createdDate = originalEstimate?.createDt ? admissionsAdvisorUtils.formatDateTime(new Date(originalEstimate?.createDt!)) : admissionsAdvisorUtils.formatDateTime(new Date());
    const levelsOfCare = newEstimate?.facilityLevelOfCare?.map(loc => loc.facilityLevelOfCareName).join(", ");
    const estimateDescription = `${levelsOfCare} - ${createdDate}`;
    copyOfEstimateState = {...copyOfEstimateState, description: estimateDescription}
 
    if (isCreateNewEstimate || createNew) {
      delete copyOfEstimateState?.estimateId;
    }

    if (
      copyOfEstimateState?.estimateId === undefined ||
        isNaN(copyOfEstimateState.estimateId as number)
    ) {
        const postEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
          vobState.vobId,
          vobState.client?.clientId!,
          vobState.facility?.facilityId!,
          copyOfEstimateState,
          vobPatientState.patient.advisorPatientId!
      );
      dispatch(
          callNewEstimatePost(postEstimateBody)
      );
    } else {
      const putEstimateBody = admissionsAdvisorUtils.mapToNewEstimate(
        vobState.vobId,
        vobState.client?.clientId!,
        vobState.facility?.facilityId!,
        copyOfEstimateState,
        vobPatientState.patient.advisorPatientId!,
        copyOfEstimateState.patientEncounterId
      );
      dispatch(callNewEstimatePut({estimate: putEstimateBody, estimateId: copyOfEstimateState?.estimateId})); 
    }

    setIsEstimateSelected(false)
    setIsCreateNewEstimate(false);
    
    if (copyOfEstimateState?.estimateId === savedEstimate?.pfrEstimateId && !ignoreIOCSave && savedEncounter !== undefined) {
        await updateSkeletonIOC()
    }
  }

  const updateSkeletonIOC = async () => {
      const iocConfig = mapToIOCModel();
      const iocResponse = await dispatch(createPatientIoc(iocConfig));
      dispatch(setSelectedEncounter(iocResponse?.payload));
      dispatch(clearPatientIocStatus());
  };

  const handleSendToCRM = async () => {
      await saveCurrentEstimate(undefined, false, true); // Save Estimate
      await updateSkeletonIOC(); // Save Skeleton IOC
      const clientCrm = admissionsAdvisorUtils.getClientCrmDetails(
          Number(newEstimate?.clientId!),
          allClients!
      );
      const isIntegrationEnabled =
          admissionsAdvisorUtils.checkIntegrationFeatureFlag(clientCrm);
      if (isIntegrationEnabled) {
          patientService.integrationUpdate({
              patientEncounterId: Number(newEstimate?.patientEncounterId!),
              crmName: clientCrm[0]?.crmType?.crmName || undefined,
              patientId: vobPatientState.patient.fpPatientId!,
          });
      }
  };

  const handleSendToFinPass = async () => {
    let vobPatientStateCopy = Utils.deepClone(vobPatientState);
    // Checking IOC Workflow status and redirecting to IOC page if it's converted
    if(newEstimate?.patientEncounterId) {
      const instanceOfCareResponse: any = await dispatch(getInstanceOfCare({patientId: vobPatientState.patient.fpPatientId!, encounterId: Number(newEstimate?.patientEncounterId!)}))
      vobPatientStateCopy.patient.selectedIOC = instanceOfCareResponse.payload.selectedPatientEncounter;
      const convertedCheck = instanceOfCareResponse.payload.selectedPatientEncounter?.workflow.workflowId === 3;
      if(convertedCheck) {
        return navigate(`/specialist/dashboard/${vobPatientState.patient.fpPatientId}`, {
            state: {
                tabIndex: 1
            }
        });
    }
  }
    dispatch(setPatient(vobPatientStateCopy.patient));
    setCompletePatientRecordModalOpen(true);
  }

  function isWorkFlowStatusManaged() {
    return (
      selectedEncounterWorkflow &&
      selectedEncounterWorkflow.workflowStatus.workflowStatusId > 0 &&
      !(
        selectedEncounterWorkflow.workflowStatus.workflowStatusId === 20 &&
        selectedEncounterWorkflow.workflowSubStatus.workflowSubStatusId === 7
      )
    );
  }

  function mapToIOCModel(): IocConfig {
      const pfrSummary: any = newEstimate?.isPlanYearCrossover
          ? newEstimate.crossOverSummary
          : newEstimate?.summary;
      const pfrAmt = newEstimate?.isPlanYearCrossover
          ? newEstimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
              ? newEstimate?.crossOverSummary?.summaryBeforeCrossover.totalPFR
              : newEstimate?.crossOverSummary?.summaryWithCrossover.totalPFR
          : pfrSummary.totalPFR;

      const levelsOfCare = newEstimate?.facilityLevelOfCare!
          .map(loc => loc.facilityLevelOfCareName)
          .join(', ');
      const truncLevelsOfCare =
          levelsOfCare?.length! > 128 ? levelsOfCare?.slice(0, 128) : levelsOfCare;
      const surchargeRateTotal = newEstimate?.isPlanYearCrossover
          ? newEstimate?.crossoverSelection === CrossoverSelectionEnum.BEFORE
              ? newEstimate?.crossOverSummary?.summaryBeforeCrossover.totalSurcharges
              : newEstimate?.crossOverSummary?.summaryWithCrossover.totalSurcharges
          : pfrSummary.totalSurcharges;
      const iocConfig: IocConfig = {
          paramId: 0,
          patientId: selectedFinPassPatient?.patientId,
          clientId: Number(newEstimate?.clientId!),
          facilityId: Number(newEstimate?.facilityId!),
          pfrAmt: pfrAmt,
          pfrEstimateId: Number(newEstimate?.estimateId),
          levelOfCare: truncLevelsOfCare,
          // typeOfCare: undefined, 
          totalSurchargeRate: surchargeRateTotal,
          timingRisk: savedEncounter?.timingRisk,
          payorRisk: savedEncounter?.payorRisk,
      };
      return iocConfig;
  }

  function isWorkFlowStatusConverted() {
    return selectedEncounterWorkflow?.workflowId === 3;
  }
  
  function checkEstimateToIoc() {
    let estimatorStatus: boolean = false
    if(newEstimate?.estimateId !== savedEstimate?.pfrEstimateId && iocIsManagedStatus) {
      estimatorStatus = true;
    }
    return estimatorStatus;
  }

  function canSendToFinPass() {
    return checkPermissions(
      userProfile.userRole.userRolePages,
      RolePageNames.AdmissionsAdvisor,
      "Send to FinPass",
      false
    );
  }

  const disableSaveAndSendToFinpassButton = !canSendToFinPass();
  const iocIsManagedStatus = isWorkFlowStatusManaged();
  const iocIsConverted = isWorkFlowStatusConverted();
  const disabledEstimate = checkEstimateToIoc();

  return (
    <>
      {newEstimate?.facilityLevelOfCare?.length! > 0 && (
        <Grid
          container
          direction="row"
          spacing={2}
          className="mt-1"
          style={{ borderTop: "1px solid #999999" }}
        >
          <Grid item direction="column" xs={10}>
            {newEstimate?.estimateId ? (
              <>
                <Grid container direction="row" spacing={1}>
                  <Grid item direction="column" md={4}>
                  <TextField
                      disabled={iocIsManagedStatus || iocIsConverted}
                      select
                      label="Saved Estimatessss"
                      name="savedEstimations"
                      loading={fetchedEstimateLoadingState}
                      value={newEstimate?.estimateId}
                      onChange={(e: Event) => {
                        handleSavedEstimationChange(e);
                      }}
                      className="state-field"
                      onBlur={() => {}}
                    >
                      {filteredEstimates.length > 0 &&
                        [...filteredEstimates]
                          ?.sort((a, b) =>
                            a.estimateBody?.description! >
                            b.estimateBody?.description!
                              ? 1
                              : -1
                          )
                          .map((estimation: PfrEstimator) => (
                            <MenuItem
                              key={estimation.pfrEstimateId}
                              value={estimation.pfrEstimateId}
                              style={{ fontWeight: estimation.pfrEstimateId === savedEstimate?.estimateBody?.pfrEstimateId ? 'bold' : 'normal' }}
                            >
                              {estimation.estimateBody?.description}
                            </MenuItem>
                          ))
                      }

                    </TextField>
                  </Grid>
                  <Grid item direction="row" md={8} style={{display: "flex"}}>
                    <Button
                      type="secondary"
                      loading={savedEstimatesLoadingState}
                      disabled={iocIsConverted || disabledEstimate}
                      onClick={() => {
                        saveCurrentEstimate();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      disabled={iocIsManagedStatus || iocIsConverted}
                      type="secondary"
                      onClick={() => {                      
                        saveCurrentEstimate(undefined, true);
                      }}
                      style={{ marginLeft: "1em" }}
                    >
                      Save As New Estimate
                    </Button>
                    <Grid item direction="column" style={{ textAlign: "left", paddingLeft: "12px" }} xs={4}>
                    {savedEstimate?.estimateBody?.description && (
                        <>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                style={{fontSize: '12px'}}
                            >
                                IoC Estimate:
                            </Typography>
                            <Tooltip
                                title={
                                    savedEstimate
                                        ?.estimateBody
                                        .description!
                                }
                            >
                                <Typography
                                    variant="body2"
                                    fontWeight="bold"
                                >
                                    {savedEstimate
                                        ?.estimateBody
                                        ?.description &&
                                    savedEstimate
                                        .estimateBody
                                        .description
                                        .length > 40
                                        ? `${savedEstimate.estimateBody.description.slice(
                                              0,
                                              34
                                          )}...`
                                        : savedEstimate
                                              ?.estimateBody
                                              ?.description}
                                </Typography>
                            </Tooltip>
                        </>
                    )}
                  </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Button
                  type="secondary"
                  onClick={() => {
                    saveCurrentEstimate(undefined, true);
                  }}
                  test-id="save-estimate-btn"
                >
                  Save
                </Button>
              </>
            )}
            
          </Grid>
          <Grid item direction="column" style={{ textAlign: "right", whiteSpace: "nowrap", paddingLeft: "0px" }} xs={2}>
          <Button
              disabled={
                  disableSaveAndSendToFinpassButton
                      ? false
                      : disabledEstimate
              }
              onClick={
                  disableSaveAndSendToFinpassButton
                      ? handleSendToCRM
                      : handleSendToFinPass
              }
          >
              {disableSaveAndSendToFinpassButton
                  ? 'Send to CRM'
                  : iocIsConverted
                  ? 'View Instance of Care'
                  : newEstimate?.patientEncounterId
                  ? 'Update Instance of Care'
                  : 'Create Instance of Care'
              }
          </Button>
          </Grid>
          
        </Grid>
      )}

      {completePatientRecordModalOpen && (
        <EstCompletePatientRecordModal
          open={completePatientRecordModalOpen}
          handlePatientRecordModalCancel={handlePatientModalCancel}
          handlePatientRecordModalSubmit={handlePatientModalSubmit}
        />
      )}
    </>
  );
}
