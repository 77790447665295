import {
    Button,
    DialogActionButton,
    saveCallbackStatus,
    Toggle,
} from '@finpay-development/shared-components';
import {
    Estimate,
    CrossoverSelectionEnum,
    QuoteMethod,
} from '@finpay/estimation-types';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {formatNumberToUSD} from 'src/shared/calculators';
import {AppDispatch} from '../../../shared/state/store';
import {callNewEstimatePut} from 'src/admissions-advisor/state/estimator-thunk';
import {saveVob} from 'src/admissions-advisor/state/vob-thunk';
import {RootState} from 'src/shared/state/root-reducer';
import {VobPostBody} from 'src/admissions-advisor/models/vob';

interface CrossoverSelectionModalProps {
    estimate: Estimate;
    open: boolean;
    handleCallbackCancel: () => void;
    handleSave: (selection: CrossoverSelectionEnum) => void;
}

const CrossoverSelectionModal = (props: CrossoverSelectionModalProps) => {
    const {open, estimate, handleCallbackCancel, handleSave} = props;

    const vob = useSelector((state: RootState) => {
        return state.admissionsAdvisorContext.vobContext.vob;
    });

    const [isCarryover, setIsCarryover] = useState(
        typeof vob.isCarryover === 'boolean' ? vob.isCarryover : false
    );
    const [isCopayReset, setIsCopayReset] = useState(
        typeof vob.isCopayReset === 'boolean' ? vob.isCopayReset : true
    );

    const dispatch = useDispatch<AppDispatch>();

    const defaultSelection =
        estimate.crossoverSelection || CrossoverSelectionEnum.BEFORE;

    const [selectedColumn, setSelectedColumn] =
        useState<number>(defaultSelection);

    const hasSelection =
        selectedColumn === CrossoverSelectionEnum.BEFORE ||
        selectedColumn === CrossoverSelectionEnum.WITH;

    // formik dosn't have an easy way to watch for changes
    // and our toggle doesn't allow us to hook into the changes of the input
    // so creating this formik to satisfy toggle's props
    const formik = useFormik({
        initialValues: {
            isCarryover,
            isCopayReset,
        },
        onSubmit: () => {},
    });

    if (!estimate.crossOverSummary) {
        return <div>Something broke</div>;
    }

    const {summaryBeforeCrossover, summaryWithCrossover} =
        estimate.crossOverSummary;

    const lightBlue = '#E8F6FF';

    const beforeCrossoverBackgroundColor =
        selectedColumn === 0 ? lightBlue : 'white';
    const withCrossoverBackgroundColor =
        selectedColumn === 1 ? lightBlue : 'white';
    const beforeCrossoverButtonType =
        selectedColumn === 0 ? 'primary' : 'secondary';
    const withCrossoverButtonType =
        selectedColumn === 1 ? 'primary' : 'secondary';
    const beforeCrossoverButtonContents =
        selectedColumn === 0 ? 'Selected' : 'Select';
    const withCrossoverButtonContents =
        selectedColumn === 1 ? 'Selected' : 'Select';

    const handleToggleChangeCopay = async () => {
        const newCopayReset = !isCopayReset;
        setIsCopayReset(newCopayReset);

        const vobBody: VobPostBody = {
            vobBody: {
                ...vob,
                isCopayReset: newCopayReset,
            },
        };
        await dispatch(saveVob(vobBody));

        if (estimate) {
            const putEstimateBody: Estimate = {
                vobId: estimate.vobId,
                clientId: estimate.clientId,
                facilityId: estimate.facilityId,
                advisorPatientId: estimate.advisorPatientId,
                patientEncounterId: 1,
                description: estimate.description,
                quoteMethod: estimate.quoteMethod || QuoteMethod.AVGLOS,
                anticipatedAdmitDate: estimate.anticipatedAdmitDate,
                facilityLevelOfCare: estimate.facilityLevelOfCare,
                isPlanYearCrossover: estimate.isPlanYearCrossover,
                isActive: true,
                crossoverSelection: estimate.crossoverSelection,
            };

            await dispatch(
                callNewEstimatePut({
                    estimate: putEstimateBody,
                    estimateId: estimate.estimateId,
                })
            );
        }
    };

    const handleToggleChangeCarryover = async () => {
        const newCarryover = !isCarryover;
        setIsCarryover(newCarryover);

        const vobBody: VobPostBody = {
            vobBody: {
                ...vob,
                isCarryover: newCarryover,
            },
        };
        await dispatch(saveVob(vobBody));

        if (estimate) {
            const putEstimateBody: Estimate = {
                vobId: estimate.vobId,
                clientId: estimate.clientId,
                facilityId: estimate.facilityId,
                advisorPatientId: estimate.advisorPatientId,
                patientEncounterId: 1,
                description: estimate.description,
                quoteMethod: estimate.quoteMethod || QuoteMethod.AVGLOS,
                anticipatedAdmitDate: estimate.anticipatedAdmitDate,
                facilityLevelOfCare: estimate.facilityLevelOfCare,
                isPlanYearCrossover: estimate.isPlanYearCrossover,
                isActive: true,
                crossoverSelection: estimate.crossoverSelection,
            };

            await dispatch(
                callNewEstimatePut({
                    estimate: putEstimateBody,
                    estimateId: estimate.estimateId,
                })
            );
        }
    };

    return (
        <Dialog
            className="modal"
            open={open}
            fullWidth={true}
            maxWidth="lg"
            scroll="body"
        >
            <DialogTitle>
                Crossover PFR Selection{' '}
                <Typography variant="h3">
                    Select a PFR amount you want to use in this estimate
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box display={'flex'}>
                    <Box className="mr-6">
                        <Typography variant="h4">
                            4th Quarter Carryover?
                        </Typography>
                        <Toggle
                            name="isCarryover"
                            value={formik.values.isCarryover}
                            formik={formik}
                            test-id="vob-modal-isCarryover"
                            onChange={handleToggleChangeCarryover}
                        />
                    </Box>
                    <Box>
                        <Typography variant="h4">Does Copay Reset?</Typography>
                        <Toggle
                            name="isCopayReset"
                            value={formik.values.isCopayReset}
                            formik={formik}
                            test-id="vob-modal-isCopayReset"
                            onChange={handleToggleChangeCopay}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(5, 1fr)',
                        gridTemplateRows: 'auto',
                        gridTemplateAreas: `"line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover" 
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"
                                "line-items line-items line-items before-crossover with-crossover"`,
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridArea: 'line-items',
                            gridTemplateColumns: 'subgrid',
                            gridTemplateRows: 'subgrid',
                        }}
                    >
                        <Box sx={{gridColumnStart: 1, gridColumnEnd: 4}}></Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Adjusted Charges
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Length of Stay
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Deductible
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Co-Insurance
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Co-Pay
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Out of Pocket Max Remaining
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            PFR of Covered Charges
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            PFR of Uncovered Charges
                        </Box>
                        <Box
                            className="p-2"
                            sx={{gridColumnStart: 1, gridColumnEnd: 4}}
                        >
                            Estimated Patient Financial Responsibility
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridArea: 'before-crossover',
                            backgroundColor: beforeCrossoverBackgroundColor,
                            gridTemplateColumns: 'subgrid',
                            gridTemplateRows: 'subgrid',
                        }}
                    >
                        <Box
                            className="p-2"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Estimate Before Crossover
                            </Typography>
                            <Button
                                type={beforeCrossoverButtonType}
                                onClick={() => {
                                    setSelectedColumn(0);
                                }}
                            >
                                {beforeCrossoverButtonContents}
                            </Button>
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.coveredCharges
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {summaryBeforeCrossover.lengthOfStay}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.deductibleSpent
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.patientTotalCoInsurance
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.patientTotalCoPays
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.remainingOopMaxFromVob
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.coveredSvcsPfr
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryBeforeCrossover.unCoveredCharges
                            )}
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(summaryBeforeCrossover.totalPFR)}
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: 'grid',
                            gridArea: 'with-crossover',
                            backgroundColor: withCrossoverBackgroundColor,
                            gridTemplateColumns: 'subgrid',
                            gridTemplateRows: 'subgrid',
                        }}
                    >
                        <Box
                            className="p-2"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="h2"
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Estimate With Crossover
                            </Typography>

                            <Button
                                type={withCrossoverButtonType}
                                onClick={() => {
                                    setSelectedColumn(1);
                                }}
                            >
                                {withCrossoverButtonContents}
                            </Button>
                        </Box>
                        <Box
                            className="py-2 px-5"
                            sx={{gridColumnStart: 4, gridColumnEnd: 5}}
                        >
                            {formatNumberToUSD(
                                summaryWithCrossover.coveredCharges
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {summaryWithCrossover.lengthOfStay}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.deductibleSpent
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.patientTotalCoInsurance
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.patientTotalCoPays
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.oopMaxFromVob
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.coveredSvcsPfr
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(
                                summaryWithCrossover.unCoveredCharges
                            )}
                        </Box>
                        <Box className="py-2 px-5">
                            {formatNumberToUSD(summaryWithCrossover.totalPFR)}
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <DialogActionButton
                    isEnabled={hasSelection}
                    savebuttonText={'Save'}
                    saveStatus={saveCallbackStatus.success}
                    spinnerLeftPosition={5}
                    executeSave={() => {
                        handleSave(selectedColumn);
                    }}
                    handleCallbackCancel={handleCallbackCancel}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CrossoverSelectionModal;
