import {Button, Toggle} from '@finpay-development/shared-components';
import CreateIcon from '@mui/icons-material/Create';
import DateRangeIcon from '@mui/icons-material/DateRange';
import {
    Box,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TableColumn} from '../../../shared/model/table-column';
import {RootState} from '../../../shared/state/root-reducer';
import {admissionsAdvisorUtils} from '../../utils/admission-advisor-utils';
import {
    Estimate,
    FacilityLevelOfCare,
    FacilityLevelOfCareWithCrossoverDays,
    PriorCareProvider,
    ThirdPartyPayerStatus,
} from '@finpay/estimation-types';
import {AppDispatch} from '../../../shared/state/store';
import {callNewEstimatePut} from '../../state/estimator-thunk';
import NewPriorCareModal from './estimator-prior-care-modal/new-estimator-prior-care-modal';
import {admissionsAdvisorService} from 'src/admissions-advisor/services/admissions-advisor-services';
import {showErrorStatus} from 'src/security/state/user-slice';

export function NewEstPriorCareSummary() {
    const [isPriorCareModalOpen, setIsPriorCareModalOpen] = useState(false);
    const [locRowsPerPage, setLocRowsPerPage] = useState(5);
    const [locPage, setLocPage] = useState(0);
    const [initialListOfLoc, setInitialListOfLoc] =
        useState<FacilityLevelOfCareWithCrossoverDays[]>();
    console.log('initialListOfLoc :>> ', initialListOfLoc);
    const locTableHeaders: TableColumn[] = [
        {id: 'levelOfCareCode', label: 'Code', minWidth: 200},
        {id: 'levelOfCareName', label: 'Name', minWidth: 200},
        {id: 'colLocDays', label: 'Days/Sessions'},
    ];

    const state = {
        vobState: useSelector(
            (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
        ),
        estimatorState: useSelector((state: RootState) => {
            const estimate =
                state.admissionsAdvisorContext.estimatorContext.newEstimate;
            return {
                ...estimate,

                /**
                 * Commented out code is for testing. Delete once modal and loc config endpoint is finished
                 * */

                // priorCare: {
                //   priorCareProvider: 1,
                //   thirdPartyPayerStatus: 0,
                //   facilityId: 110389,
                //   loc: [
                //     {
                //       facilityLevelOfCareId: 5602,
                //       facilityLevelOfCareCode: '0126 / H0010',
                //       facilityLevelOfCareName: 'Detox',
                //       sortOrder: 1,
                //       perDiemOperatingCost: 0,
                //       minRevenueAmount: 0,
                //       minRevenueFlatRate: false,
                //       pdrRate: 500,
                //       losDays: 26,
                //       recommendedLos: 10,
                //       isCovered: true,
                //       vobClassificationId: 1,
                //       facilityPayorPlanId: 3552,
                //       locType: 0,
                //       cfgLevelOfCareId: 1,
                //       entryType: 0,
                //     },
                //     {
                //       facilityLevelOfCareId: 5623,
                //       facilityLevelOfCareCode: '101',
                //       facilityLevelOfCareName: 'Transportation',
                //       sortOrder: 4,
                //       perDiemOperatingCost: 0,
                //       minRevenueAmount: 0,
                //       minRevenueFlatRate: false,
                //       pdrRate: 800,
                //       losDays: 1,
                //       recommendedLos: 2,
                //       isCovered: false,
                //       vobClassificationId: 1,
                //       facilityPayorPlanId: 3552,
                //       locType: 0,
                //       cfgLevelOfCareId: 1188,
                //     },
                //   ],
                // },
            } as Estimate;
        }),
        facilities: useSelector(
            (state: RootState) =>
                state.implementationContext.implementationFacility.facilities
        ),
        newFacilityLevelsOfCareList: useSelector((state: RootState) => {
            return state?.admissionsAdvisorContext.estimatorContext
                .newFacilityLevelsOfCareList;
        }),
    };

    const {vobState, estimatorState, facilities, newFacilityLevelsOfCareList} =
        state;

    const facilityId = estimatorState.facilityId as number;

    const isEditMode = Boolean(
        estimatorState.priorCare && estimatorState.priorCare.loc
    );

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        console.log('estimatorState.priorCare :>> ', estimatorState.priorCare);
        const provider = estimatorState?.priorCare?.priorCareProvider;
        const networkSelection =
            estimatorState?.priorCare?.thirdPartyPayerStatus;

        if (provider === PriorCareProvider.THIRDPARTY) {
            const inn =
                networkSelection === ThirdPartyPayerStatus.INNETW ? 'y' : 'n';

            admissionsAdvisorService
                .getMasterFacilityLevelsOfCare(facilityId, inn)
                .then(result => {
                    const newLoc = result.hasErrors
                        ? []
                        : result.entity?.filter(
                              (loc: FacilityLevelOfCareWithCrossoverDays) => {
                                  return loc.isCovered;
                              }
                          );
                    setInitialListOfLoc(newLoc);
                })
                .catch(err => {
                    dispatch(showErrorStatus('Error fetching third party LOC'));
                });
        } else {
            setInitialListOfLoc(
                newFacilityLevelsOfCareList as FacilityLevelOfCareWithCrossoverDays[]
            );
        }
    }, [
        estimatorState.priorCare?.priorCareProvider,
        estimatorState.priorCare?.thirdPartyPayerStatus,
    ]);

    const handleLocChangePage = (event: unknown, newPage: number) => {
        setLocPage(newPage);
    };

    const handleChangeLocRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLocRowsPerPage(+event.target.value);
        setLocPage(0);
    };

    const doesPriorCareExist =
        Array.isArray(estimatorState?.priorCare?.loc) &&
        estimatorState?.priorCare?.loc.length! > 0;

    const priorCareSummaryFormik = useFormik({
        initialValues: {
            priorCareYesNo: doesPriorCareExist,
        },
        onSubmit: () => {},
        enableReinitialize: true,
    });

    const handleHasPriorCareChange = async () => {
        const isAddingPriorCare = !priorCareSummaryFormik.values.priorCareYesNo;

        if (!isAddingPriorCare && estimatorState.priorCare) {
            await dispatch(
                callNewEstimatePut({
                    estimateId: estimatorState.estimateId,
                    estimate: _.omit(estimatorState, [
                        'priorCare',
                        'estimateId',
                        'summary',
                        'createUserId',
                        'createDt',
                        'lastUpdateUserId',
                        'lastUpdateDt',
                        'priorCareSummary',
                        'crossOverSummary',
                    ]),
                })
            );
        }
    };

    const handleModalCancel = () => {
        setIsPriorCareModalOpen(false);
    };

    const handleModalSubmit = () => {
        setIsPriorCareModalOpen(false);
    };

    return (
        <>
            <Paper>
                <Box
                    style={{
                        padding: '1.0em',
                        borderBottom: '1px solid #cccccc',
                        marginBottom: '1.5em',
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            justifyContent="center"
                            direction="column"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <DateRangeIcon sx={{marginRight: '0.25em'}} />{' '}
                                Adjustments for Prior Care
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Toggle
                                leftText="Yes"
                                rightText="No"
                                name="priorCareYesNo"
                                onChange={() => {
                                    handleHasPriorCareChange();
                                }}
                                value={
                                    priorCareSummaryFormik.values.priorCareYesNo
                                }
                                formik={priorCareSummaryFormik}
                            />
                        </Grid>
                    </Grid>
                    <Grid container direction="row">
                        {(() => {
                            switch (true) {
                                case doesPriorCareExist: {
                                    const priorLevelsOfCareSummary =
                                        admissionsAdvisorUtils.getNewPriorLevelsOfCare(
                                            estimatorState?.priorCare!,
                                            facilities
                                        );
                                    const genericPriorLoc =
                                        admissionsAdvisorUtils.getNewGenericPriorAdjChg(
                                            estimatorState?.priorCare!,
                                            estimatorState!,
                                            vobState
                                        );
                                    return (
                                        <>
                                            <>
                                                <Grid
                                                    item
                                                    direction="column"
                                                    md={5}
                                                >
                                                    <Typography variant="body2">
                                                        {
                                                            priorLevelsOfCareSummary?.labelOne
                                                        }
                                                    </Typography>
                                                    {
                                                        priorLevelsOfCareSummary?.valueOne
                                                    }
                                                </Grid>
                                                <Grid
                                                    item
                                                    direction="column"
                                                    md={5}
                                                >
                                                    <Typography variant="body2">
                                                        {
                                                            priorLevelsOfCareSummary?.labelTwo
                                                        }
                                                    </Typography>
                                                    {
                                                        priorLevelsOfCareSummary?.valueTwo
                                                    }
                                                </Grid>
                                                <Grid
                                                    item
                                                    direction="column"
                                                    md={2}
                                                    style={{textAlign: 'right'}}
                                                >
                                                    <IconButton
                                                        color="primary"
                                                        title="Edit Prior Care"
                                                        style={{
                                                            fontSize: '1.5rem',
                                                        }}
                                                        onClick={() =>
                                                            setIsPriorCareModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <CreateIcon /> Edit
                                                    </IconButton>
                                                </Grid>
                                                <TableContainer
                                                    component={Paper}
                                                >
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                {locTableHeaders.map(
                                                                    column => (
                                                                        <TableCell
                                                                            key={
                                                                                column.id
                                                                            }
                                                                        >
                                                                            {
                                                                                column.label
                                                                            }
                                                                        </TableCell>
                                                                    )
                                                                )}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {_.sortBy(
                                                                genericPriorLoc,
                                                                'name'
                                                            ).slice!(
                                                                locPage *
                                                                    locRowsPerPage,
                                                                locPage *
                                                                    locRowsPerPage +
                                                                    locRowsPerPage
                                                            ).map(
                                                                (
                                                                    row,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <TableRow
                                                                            key={`${row.code}.${index}`}
                                                                        >
                                                                            <TableCell>
                                                                                {
                                                                                    row.code
                                                                                }
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    row.name
                                                                                }
                                                                                <div
                                                                                    style={{
                                                                                        fontSize:
                                                                                            '8pt',
                                                                                    }}
                                                                                >
                                                                                    {admissionsAdvisorUtils.getFacilityName(
                                                                                        row.facilityId,
                                                                                        facilities
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {
                                                                                    row.days
                                                                                }
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                }
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <TablePagination
                                                                component="div"
                                                                rowsPerPageOptions={[
                                                                    5,
                                                                    10,
                                                                    50,
                                                                    {
                                                                        value: genericPriorLoc?.length!,
                                                                        label: 'All',
                                                                    },
                                                                ]}
                                                                count={
                                                                    genericPriorLoc?.length!
                                                                }
                                                                rowsPerPage={
                                                                    locRowsPerPage
                                                                }
                                                                page={locPage}
                                                                labelRowsPerPage={
                                                                    'View'
                                                                }
                                                                onPageChange={
                                                                    handleLocChangePage
                                                                }
                                                                onRowsPerPageChange={
                                                                    handleChangeLocRowsPerPage
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableContainer>
                                            </>
                                        </>
                                    );
                                }
                                case priorCareSummaryFormik.values
                                    .priorCareYesNo: {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                textAlign: 'center',
                                                padding: '1em',
                                            }}
                                        >
                                            <Button
                                                onClick={() =>
                                                    setIsPriorCareModalOpen(
                                                        true
                                                    )
                                                }
                                            >
                                                Enter Prior Care
                                            </Button>
                                        </Grid>
                                    );
                                }
                                default:
                                    return <></>;
                            }
                        })()}
                    </Grid>
                </Box>
            </Paper>
            {estimatorState &&
                estimatorState.estimateId &&
                isPriorCareModalOpen &&
                initialListOfLoc &&
                initialListOfLoc.length > 0 && (
                    <NewPriorCareModal
                        open={isPriorCareModalOpen}
                        isEdit={isEditMode}
                        facilities={facilities}
                        facilityId={facilityId}
                        vob={vobState}
                        initialListOfLoc={initialListOfLoc}
                        priorCare={estimatorState.priorCare}
                        handlePriorCareModalCancel={handleModalCancel}
                        handlePriorCareModalSubmit={handleModalSubmit}
                    />
                )}
        </>
    );
}
