import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/state/root-reducer";
import { formatNumberToUSD } from "../../../shared/calculators";
import { Typography, Box, Grid, IconButton } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AppDispatch } from "../../../shared/state/store";
import { EstFinancialAssistanceSummaryModalNew } from "./estimator-financial-assistance-summary-modal-new";
import { CrossoverSelectionEnum } from "@finpay/estimation-types";

export function SidebarFinancialAssistanceNew() {

  const state = {
    vobState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.newEstimate
    ),
  };

	const {
		vobState,
		estimatorState
	} = state;

	const [financialSummaryModalOpen, setFinancialSummaryModalOpen] = useState(false);
	const [adjustedPFR, setAdjustedPFR] = useState<number>(0.00);
	const dispatch = useDispatch<AppDispatch>();
	const PFRName = "Financial Assistance PFR";

	useEffect(() => {
		// calculate PFR for plan crossover
    if (estimatorState !== null) {
      const pfrSummary: any = estimatorState?.isPlanYearCrossover
        ? estimatorState.crossOverSummary
        : estimatorState?.summary;
      const pfrAmt = estimatorState?.isPlanYearCrossover
        ? estimatorState?.crossoverSelection === CrossoverSelectionEnum.BEFORE
            ? estimatorState?.crossOverSummary?.summaryBeforeCrossover.totalPFR
            : estimatorState?.crossOverSummary?.summaryWithCrossover.totalPFR
        : pfrSummary.totalPFR;

      setAdjustedPFR(pfrAmt);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimatorState]);

	const getSideBarColor = (): string => {
    let sideBarColor = "#6EACDE";
    return sideBarColor;
  };

	const handleFinSumModalCancel = () => {
    setFinancialSummaryModalOpen(false);
  };

	const openFinancialSummaryModal = () => {
		setFinancialSummaryModalOpen(true);
	}

	return (
		<>

			{(estimatorState?.facilityLevelOfCare?.length! > 0 && estimatorState?.financialAssistance !== undefined && estimatorState?.financialAssistance?.qualifiesForAssistance) && (
				<>
          <Box
            border={1}
            borderColor={getSideBarColor()}
						style={{
							backgroundColor: "white",
							marginBottom: "1.5em",
						}}
          >
            <Grid container direction="row">
              <Grid item direction="column" md={12}></Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
                item
                direction="column"
                md={12}
								xs={12}
                style={{
                  color: "white",
                  backgroundColor: getSideBarColor(),
                  padding: "0.25em",
                }}
              >
                Financial Assistance
              </Grid>
              <Grid
                item
                direction="column"
                md={12}
                style={{
                  padding: "1em",
                }}
              >
                <Typography variant="body2" style={{ float: "left" }}>
                  Estimated PFR After Financial Assistance
                </Typography>
                <Typography variant="body2" style={{ float: "right" }}>
                    <strong style={{color: "black", fontSize: "16px"}}>{formatNumberToUSD(adjustedPFR)}</strong>
                </Typography>
                

              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid
								item
								direction="column"
								md={12}
								display="flex"
								justifyContent="center"
								alignItems="center"
							>
								<IconButton
									color="primary"
                  title="Open Details"
                  style={{ fontSize: "1.5rem" }}
                  onClick={openFinancialSummaryModal}
                >
                  <OpenInNewIcon fontSize="small" /> Open Details
                </IconButton>
							</Grid>
            </Grid>
          </Box>

					{financialSummaryModalOpen && (
						<EstFinancialAssistanceSummaryModalNew
							open={financialSummaryModalOpen}
							handleFinancialSummaryModalCancel={handleFinSumModalCancel }
						/>
					)}

				</>

      )}

		</>
	)
	


}