import {
  DatePicker,
  LoadingOverlay,
  TextField,
  LOCSummary,
  BuildSelectedLevelsofCare,
  NewLOCSummary,
} from '@finpay-development/shared-components';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Grid, MenuItem, Paper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import {
  ImplementationFacility,
} from '../../../implementation-specialist/components/implementation-clients/details/models/implementation-facility';
import { getClientFacilities } from '../../../implementation-specialist/state/facility/implementation-facility-thunk';
import { ClientStatusCardViewModel } from '../../../shared/model/client-status-card';
import { RootState } from '../../../shared/state/root-reducer';
import { AppDispatch } from '../../../shared/state/store';
import { estimatorSchema } from '../../../shared/validation/schemas';
import { clearNewEstimate, setEstimator } from '../../state/estimator-slice';
import { EstFinAssistanceSummary } from './estimator-financial-assistance-summary';
import { EstFooter } from './estimator-footer';
import { NewEstFooter } from './estimator-footer-new';
import { EstPriorCareSummary } from './estimator-prior-care-summary';
import { SidebarFinancialSummary } from './sidebar-financial-summary';
import { SidebarPlanYearCrossover } from './sidebar-plan-year-crossover';
import { SidebarPriorCareAdjustment } from './sidebar-prior-care-adjustment';
import { SidebarTotalEstimatedPfr } from './sidebar-total-estimated-pfr';
import { SidebarFinancialAssistance } from './sidebar-financial-assistance';
import { PatientSearchArea } from '../vob/patient-search-area';
import { clearPatient, setPatient } from '../../state/vob-patient-slice';
import { clearFacilityPayers, clearPaymentURLParams, clearVOB, setVOB } from '../../state/vob-slice';
import { clearEstimator } from '../../state/estimator-slice';
import { clearRiskAssessment } from "../../state/risk-assessment-slice";
import { Utils } from '../../../shared/utils';
import { EditEstLevelOfCare, Estimate, EstimatorBody, PayorLocRateLos} from '../../models/estimator';
import { CrmPrefilledLoC } from '../../../admin-configuration/models/level-of-care';

import { getClientLevelsOfCare, getPatientDemographics } from 'src/patient/state/patient-thunk';
import { TakePaymentModal } from 'src/shared/components/take-payment-modal';
import { PAYMENT_TYPES } from 'src/patient/components/models/payment-type';
import {
  EstLevelOfCareModal, stringToQuoteMethod, quoteMethodToString,
} from './estimator-loc-modal/estimator-loc-modal';
import AlertBoxes from '../vob/alert-box';
import { getAlerts } from 'src/meta-data/state/meta-data-thunk';
import { Alert } from 'src/meta-data/models/metaData';
import { getEstimatorFacilityPayers } from 'src/admissions-advisor/state/vob-thunk';
import { FacilityPayers } from '@finpay-development/shared-components/lib/esm/models/vob';
import { Vob, emptyVob } from 'src/admissions-advisor/models/vob';
import { showErrorStatus } from '../../../security/state/user-slice';
import { admissionsAdvisorUtils } from 'src/admissions-advisor/utils/admission-advisor-utils';
import { finAdvisorErrorMessage } from '../../../shared/enums';
import useIsClientConfiguredForNewEstimate from 'src/admissions-advisor/utils/useClientConfigHook';
import { getNewFacilityLevelsOfCare } from 'src/admissions-advisor/state/estimator-thunk';
import {callNewEstimatePut} from '../../state/estimator-thunk';
import {
  Estimate as NewEstimate,
  QuoteMethod
} from '@finpay/estimation-types';
import {NewEstPriorCareSummary} from './new-estimator-prior-care-summary';
import { EstFinAssistanceSummaryNew } from './estimator-financial-assistance-summary-new';
import { SidebarFinancialAssistanceNew } from './sidebar-financial-assistance-new';


interface VobProps {
	handleEstimatorTabClick1: (tab: number) => void;
  }

export const locModalErrorHandling = (estimatorState: Estimate, createEstimatorFormik: any, vob: Vob, dispatch: AppDispatch) => {
	const locErrorConditions = [
        {
            state: createEstimatorFormik.values.client,
            message: finAdvisorErrorMessage.missingClient,
        },
        {
            state: createEstimatorFormik.values.facility,
            message: finAdvisorErrorMessage.missingFacility,
        },
        {
            state: vob.payer?.payorId && vob.payer?.payorId > 0,
            message: finAdvisorErrorMessage.missingPayer,
        },
        {
            state: vob.plan?.payorPlanId && vob.plan?.payorPlanId > 0,
            message: finAdvisorErrorMessage.missingPlan,
        },
		{
            state: createEstimatorFormik.values.quoteMethod,
            message: finAdvisorErrorMessage.missingQuoteMethod,
        }
    ];

    for (const {state, message} of locErrorConditions) {
        if (!state) {
            const estimatorStateCopy = { ...estimatorState };
            estimatorStateCopy.selectedLevelsOfCare = [];
            dispatch(setEstimator(estimatorStateCopy));
            dispatch(showErrorStatus(message));
            return true;
        }
    }

    return false; 
};

function Estimator(props: VobProps) {

  const paramId = -2;

  const state = {
    clients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient
        .filteredByIsActive
    ),
    facilities: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationFacility.facilities
    ),
    isLoadingClients: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationSpecialistClient.isLoading
    ),
    isLoadingFacilities: useSelector(
      (state: RootState) =>
      state.implementationContext.implementationFacility.isLoading
    ),
    vob: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobContext.vob
    ),
    vobPatientState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.vobPatientContext
      ),
    vobPatient: useSelector(
      (state: RootState) =>
        state.admissionsAdvisorContext?.vobPatientContext.patient
    ),
    estimatorState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.estimator
    ),
    estimatorLoadingState: useSelector(
      (state: RootState) => state.admissionsAdvisorContext.estimatorContext.isLoading
    ),
    clientAlerts: useSelector(
      (state: RootState) => {
        return state.metaData.alerts
      }
    ),
    clientLevelsOfCareState: useSelector(
      (state: RootState) => {
        return state.admissionsAdvisorContext.estimatorContext?.clientLevelsOfCare
      }
    ),
    payers: useSelector(
      (state: RootState) =>
          state.adminContext.adminConfigurationContext?.payers
    ),
	masterLevelsOfCareState: useSelector(
		(state: RootState) => {
			return state?.adminContext.adminConfigurationContext?.levelsOfCare
		}
	),
	newEstimate: useSelector(
		(state: RootState) => {
			return state?.admissionsAdvisorContext.estimatorContext.newEstimate
		}
	),
	newFacilityLevelsOfCareList: useSelector(
		(state: RootState) => {
			return state?.admissionsAdvisorContext.estimatorContext.newFacilityLevelsOfCareList
		}
	),
  };

const {
	clients,
	facilities,
	isLoadingClients,
	isLoadingFacilities,
	vob,
	vobPatientState,
	vobPatient,
	estimatorLoadingState,
	estimatorState,
	clientAlerts,
	clientLevelsOfCareState,
  	payers,
	masterLevelsOfCareState,
	newEstimate,
	newFacilityLevelsOfCareList
} = state;

const isClientConfiguredForNewEstimate = useIsClientConfiguredForNewEstimate()

  const { handleEstimatorTabClick1 } = props;
  const [enableLOSbutton, setEnableLOSbutton] = useState(!estimatorLoadingState.isLoadingSaveEstimates);
  const [isLOCModalOpen, setIsLOCModalOpen] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState<any>(undefined);

  const [payerAlerts, setPayerAlerts] = useState<Alert[]>([])
  const [employerAlerts, setEmployerAlerts] = useState<Alert[]>([])
  const [groupAlerts, setGroupAlerts] = useState<Alert[]>([])
  const [prefixAlerts, setPrefixAlerts] = useState<Alert[]>([])

  const [doesLOCSummaryValNeedUpdating, setDoesLOCSummaryValNeedUpdating] = useState(false)

  const dispatch = useDispatch<AppDispatch>();

useEffect(() => {
	forceValidateOnload();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch]);

// if the client is configured with the feature flag, use the new estimate values, otherwise use the estimator state values, otherwise use the vob values
const currentClientId = isClientConfiguredForNewEstimate && newEstimate?.clientId ? newEstimate.clientId : estimatorState?.client?.clientId ? estimatorState?.client?.clientId : vob?.client?.clientId
const currentFacilityId = isClientConfiguredForNewEstimate && newEstimate?.facilityId ? newEstimate.facilityId : estimatorState.facility.facilityId ? estimatorState.facility.facilityId : vob?.facility?.facilityId
const initialAdmissionDate = isClientConfiguredForNewEstimate && newEstimate?.anticipatedAdmitDate ? newEstimate.anticipatedAdmitDate : estimatorState.admissionDate ? estimatorState.admissionDate : new Date().toDateString()
const initialQuoteMethod = isClientConfiguredForNewEstimate && newEstimate?.quoteMethod ? newEstimate.quoteMethod : estimatorState.quoteMethod ? estimatorState.quoteMethod : "avglos"

useEffect(() => {
	if(isClientConfiguredForNewEstimate && vob?.plan?.facilityPlanId) {
		dispatch(getNewFacilityLevelsOfCare({facilityId: currentFacilityId!, facilityPayorPlanId: vob?.plan?.facilityPlanId!}));
	}
}, [currentFacilityId]);

useEffect(() => {
	if(!vob.isValid) {
		handleEstimatorTabClick1(0);
	}
	if (vob?.urlParams?.op === "pay") {
		setPaymentData({
            patientId: vobPatient?.fpPatientId,
            patientEncounterId: estimatorState?.finPay?.patientEncounterId,
            paymentType: PAYMENT_TYPES.DOWN_PAYMENT,
			paymentChannelId: 3
          });
		setOpenPaymentModal(true);
	  }
	// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vob]);

  // setting up clientAlerts
  // needs client id from vob and the meta data
  useEffect(() => {
	const clientId = vob?.client?.clientId

	// possible that we are still loading vob
	if(!clientId) return

	const alerts = clientAlerts[clientId]

	// if we have a client id and our client's alerts, set everything
	if(clientId && alerts) {

		const payerAlerts = clientAlerts[clientId].payer.filter((payerAlert) => {
			return payerAlert.payor_id === vob.payer.payorId
		})

		const groupAlerts = clientAlerts[clientId].group.filter((groupAlert) => {
			return groupAlert?.group_code === vob.groupNum
		})

		const employerAlerts = clientAlerts[clientId].employer.filter((employerAlert) => {
			return employerAlert.client_employer_id === vob.employer?.employerId
		})

		let newPrefixAlerts: Alert[] = []
        const prefixes = clientAlerts[clientId].prefix

        for(let i = 0; i < prefixes.length; i++) {
            if(prefixes.length > 0) {
                const prefixAlert = prefixes[i]
                const subStringEndingIndex = prefixAlert.prefix!.length;

                const policyNumberSubString = vob?.policyNum?.substring(0, subStringEndingIndex)

                const isAMatch = policyNumberSubString === prefixAlert.prefix
                // match
                if(isAMatch) {
                    // if nothing in the new alerts, add it
                    if(newPrefixAlerts.length === 0) {
                        newPrefixAlerts.push(prefixAlert)
                        continue;
                    }

                    // length is same as the first prefix
                    if(newPrefixAlerts[0].prefix!.length === prefixAlert.prefix!.length) {
                        // add it the list, don't replace
                        newPrefixAlerts.push(prefixAlert)
                        continue
                    }

                    // length is greater than the first prefix
                    if(newPrefixAlerts[0].prefix!.length < prefixAlert.prefix!.length) {
                        // replace with new alert
                        newPrefixAlerts = [prefixAlert]
                        continue
                    }
                }
            }

        }


		setPayerAlerts(payerAlerts)
		setGroupAlerts(groupAlerts)
		setPrefixAlerts(newPrefixAlerts)
		setEmployerAlerts(employerAlerts)

	} else if(clientId && !clientAlerts[clientId]) {
		// client id but no alerts, fetch the alerts
		dispatch(getAlerts(clientId))
	}
  }, [vob, clientAlerts])

  const setAdvisorPatientBody = async () => {
      if(vobPatientState.patient.fpPatientId) {

      const { payload }: { payload: any} = await dispatch(
        getPatientDemographics(vobPatientState.patient.fpPatientId!)
      );

      await dispatch(
          setPatient({
            advisorPatientBody: {
              email: payload.patientDetails.contact.email,
              primaryAddress: {
                streetAddress1: payload.patientDetails.contact.primaryAddress.streetAddress1,
                streetAddress2: payload.patientDetails.contact.primaryAddress.streetAddress2,
                city: payload.patientDetails.contact.primaryAddress.city,
                state: {
                  stateCode: payload.patientDetails.contact.primaryAddress?.state?.stateCode,
                },
                zipCode: payload.patientDetails.contact.primaryAddress?.zipCode
              },
              phoneNumber: payload.patientDetails.contact.primPhoneNum,
            }
          })
      );
        }
  }

  useEffect(() => {
    setAdvisorPatientBody()
  }, [])

useEffect(() => {
	const fetchData = async () => {
		const handlePrefillLoc = async () => {
			const getLocConfig: any = {
				paramId: -2,
				clientId: vob?.client?.clientId,
				payerPlanId: vob?.plan?.payorPlanId
					? vob?.plan?.payorPlanId
					: undefined,
				filterByPayerPlanId: !!vob?.plan?.payorPlanId,
				masterListLevelsOfCare: masterLevelsOfCareState,
			};
			const getClientLocResponse: any = await dispatch(getClientLevelsOfCare(getLocConfig));
			const clientFacilitylocArray: EditEstLevelOfCare[] = getClientLocResponse.payload;

			const estimateCopy = Utils.deepClone(estimatorState);
			const clientFacilityId = vob?.facility?.facilityId;
			const matchLevelsOfCare: EditEstLevelOfCare[] =
				estimateCopy?.crmPrefilledLoC
					?.map((estimateLoc: CrmPrefilledLoC) => {
						const matchingLevelofCare = clientFacilitylocArray.find(
							obj =>
								obj.facilityLevelOfCareCode ===
									estimateLoc.locCode &&
								obj.clientFacilityId === clientFacilityId
						);

						return (
							matchingLevelofCare ||
							clientFacilitylocArray.find(
								obj =>
									new RegExp(estimateLoc.locName, 'i').test(
										obj.facilityLevelOfCareName!
									) && obj.clientFacilityId === clientFacilityId
							)
						);
					})
					.filter(Boolean);
			const facilityLocs: EditEstLevelOfCare[] = BuildSelectedLevelsofCare(
				matchLevelsOfCare,
				clientFacilityId!,
				// TODO: in this block, quote method will alway be the old string, not the new enum
				// @ts-ignore
				createEstimatorFormik.values.quoteMethod || "avglos",
				vob.payer.inNetwork!,
				vob.selfPay!
			);
			return facilityLocs;
		};

		let estimatorStateCopy = { ...estimatorState };
		console.log('%cOnload Estimator State:', 'background-color: orange; color: white; font-weight: bold', '', estimatorStateCopy);
		console.log('%cOnload Vob State:', 'background-color: purple; color: white; font-weight: bold', '', vob);
		console.log('%cOnload Client LOCState:', 'background-color: green; color: white; font-weight: bold', clientLevelsOfCareState);

		if (!estimatorStateCopy.client.clientId) {
			estimatorStateCopy.client = {
				clientName: vob?.client?.clientName,
				clientId: vob?.client?.clientId
			};
		}

		if (!estimatorStateCopy.facility.facilityId) {
			estimatorStateCopy.facility = {
				facilityId: vob?.facility?.facilityId,
				facilityName: vob?.facility?.facilityName,
			}
		}

		if (
			estimatorStateCopy?.crmPrefilledLoC!?.length > 0 &&
			estimatorStateCopy?.selectedLevelsOfCare.length === 0
		) {
			estimatorStateCopy.selectedLevelsOfCare = await handlePrefillLoc();
		}

		if (!estimatorStateCopy.quoteMethod) estimatorStateCopy.quoteMethod = "avglos"

		if (!estimatorStateCopy.serviceLevel) estimatorStateCopy.serviceLevel = "inpatient"

		if (!estimatorStateCopy.admissionDate) estimatorStateCopy.admissionDate = new Date().toDateString()

		dispatch(setEstimator(estimatorStateCopy));
	};
	if(!isClientConfiguredForNewEstimate) {
		fetchData();
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


const handleLocModalOpen = async () => {
	const hasErrors = locModalErrorHandling(estimatorState, createEstimatorFormik, vob, dispatch);

    if (!hasErrors) {
        setIsLOCModalOpen(true);
    }
};

const clickEstimatorTab = (tab: number) => {
	handleEstimatorTabClick1(tab)
}

async function handleEstimatorLevelOfCareModalClose(){
	console.log('%cLOC Modal Close - Formik:', 'background-color: #0199cc; color: white; font-weight: bold', createEstimatorFormik.values);
	console.log('%cLOC Modal Close - Estimator State:', 'background-color: #0199cc; color: white; font-weight: bold', estimatorState);
	console.log('%cLOC Modal Open - Vob State:', 'background-color: #0199cc; color: white; font-weight: bold', vob);
	console.log('%cLOC Modal Open - Client LOCState:', 'background-color: #0199cc; color: white; font-weight: bold', clientLevelsOfCareState);
	setIsLOCModalOpen(false);
}

const estimatorValidationSchema = Yup.object(estimatorSchema);

function checkIfEstimatorIsValid(value: any) {
  estimatorValidationSchema
    .validate(value)
    .then(() => {
			if (Utils.isValidDate(value.admissionDate)) {
				setEnableLOSbutton(true);
			}
    })
    .catch(() => {
      setEnableLOSbutton(false);
    });
}

  // formik for estimator
  const createEstimatorFormik = useFormik({
    initialValues: {
      client: currentClientId,
      facility: currentFacilityId,
	  quoteMethod: initialQuoteMethod,
	//   serviceLevel: estimatorState?.serviceLevel,
	  admissionDate: initialAdmissionDate
    },
    validate: checkIfEstimatorIsValid,
		enableReinitialize: true,
    validationSchema: estimatorValidationSchema,
    onSubmit: () => {},
  });

  const handleClientChange = (e: Event) => {
    const selectedClientId = (e.target as HTMLInputElement).value;
		let estimatorStateCopy = clearSavedLOC();
		createEstimatorFormik.isValid = false;
    dispatch(getClientFacilities(+selectedClientId));
		// update state
    const clientToSave = clients?.find(
      (client) => client?.clientId === +selectedClientId
    );
    estimatorStateCopy.client = {
      clientId: clientToSave?.clientId,
      clientName: clientToSave?.clientName,
    };
    dispatch(setEstimator(estimatorStateCopy));
  };

  const getFacilityLocPayers = async (facilityId: number) => {
    const config: any = {
      paramId: paramId,
      masterListPayers: payers,
      facilityId: facilityId,
    };

    return await dispatch(getEstimatorFacilityPayers(config));
  };

	const handleFacilityChange = async (e: Event) => {
    const selectedFacilityId = (e.target as HTMLInputElement).value;

		let estimatorStateCopy = clearSavedLOC();

		createEstimatorFormik.isValid = false;

		// update state
		const facilityToSave = facilities?.flat().find(
			(fac) => fac?.facilityId === +selectedFacilityId
		);

		estimatorStateCopy.facility = {
			facilityId: facilityToSave?.facilityId,
			facilityName: facilityToSave?.facilityName,
		};

		const vobStateCopy = {...vob};

		// resetting selected LOC list if payer or plan do not exist
		if (
			vobStateCopy.payer?.payorId === undefined ||
			vobStateCopy.plan?.payorPlanId === undefined
		) {
			estimatorStateCopy.selectedLevelsOfCare = [];
		}

		vobStateCopy.facility = {
			facilityId: facilityToSave?.facilityId,
			facilityName: facilityToSave?.facilityName,
		}

    // need to see if payer/plan matches with new facility payer/plans
    const { payload } = await getFacilityLocPayers(+selectedFacilityId);

		if(payload) {
      const facilityPayers = [...(payload as FacilityPayers[])].sort((a, b) => a.facilityPayorName! > b.facilityPayorName! ? 1 : -1)

      const payer = admissionsAdvisorUtils.comparePayers(facilityPayers, vobStateCopy)

      if(payer) {
        const vobPayer = {
          payorId: payer?.cfgPayorId,
          payorName: payer?.facilityPayorName,
          payorCode: payer?.facilityPayorCode,
          inNetwork: payer?.inNetwork,
          payersList: facilityPayers,
        };

        vobStateCopy.payer = vobPayer

        const plan = admissionsAdvisorUtils.comparePlans(payer, vobStateCopy)

        if(plan) {
          const sortedPlans = [...payer.facilityPayorPlans].sort((a, b) =>
            a.facilityPayorPlanName! > b.facilityPayorPlanName! ? 1 : -1
          )

          const vobPlan = {
            payorPlanId: plan?.cfgPayorPlanId,
            planName: plan?.facilityPayorPlanName,
            planCode: plan?.facilityPayorPlanCode,
            facilityPlanId: plan?.facilityPayorPlanId,
            pdrRate: (plan?.facilityPayorLocRateLos as PayorLocRateLos)
                ?.pdrRate,
            losDays: (plan?.facilityPayorLocRateLos as PayorLocRateLos)
                ?.losDays,
            payersPlanList: sortedPlans,
          };

          vobStateCopy.plan = vobPlan
        } else {
          vobStateCopy.plan = emptyVob.plan
          // toast
          dispatch(showErrorStatus("Could not find matching plan. Please update VOB with new plan."))
        }

      } else {
        vobStateCopy.payer = emptyVob.payer
        vobStateCopy.plan = emptyVob.plan
        // toast 
        dispatch(showErrorStatus("Could not find matching payer or plan. Please update VOB with new payer and plan."))
      }

    }
		dispatch(setVOB(vobStateCopy));
		dispatch(setEstimator(estimatorStateCopy));
  };

	const handleQuoteMethodChange = (e: Event) => {
		const selectedQuoteMethod = (e.target as HTMLInputElement).value;
		let estimatorStateCopy = Utils.deepClone(estimatorState);
    estimatorStateCopy.quoteMethod = selectedQuoteMethod;
    dispatch(setEstimator(estimatorStateCopy));
	}

  const handleNewEstimateQuoteMethodChange = (e: Event) => {

    try{

      const selectedQuoteMethod = (e.target as HTMLInputElement).value;

      if(newEstimate){  //estimate already exists

        let putEstimateBody: NewEstimate | undefined;

        switch(true){

          case selectedQuoteMethod === 'sca' : {

            setDoesLOCSummaryValNeedUpdating(true);

            putEstimateBody = {
              vobId: vob.vobId!,
              clientId:
              newEstimate?.clientId,
              facilityId:
              newEstimate?.facilityId,
              advisorPatientId:
              newEstimate?.advisorPatientId,
              description:
              newEstimate?.description,
              quoteMethod: stringToQuoteMethod(selectedQuoteMethod),
              anticipatedAdmitDate: new Date(createEstimatorFormik.values.admissionDate),
              facilityLevelOfCare: newEstimate.facilityLevelOfCare,
              priorCare: newEstimate.priorCare,
              isPlanYearCrossover: newEstimate.isPlanYearCrossover,
              crossoverSelection:
              newEstimate?.crossoverSelection,
              isActive: newEstimate.isActive,
            };

            break;
          }

          case selectedQuoteMethod === 'manual': {

            setDoesLOCSummaryValNeedUpdating(true);

            const updatedFacilityLevelOfCare = newEstimate?.facilityLevelOfCare?.map((facLOC) => {

              const foundFacLOCFromList = newFacilityLevelsOfCareList?.find((facLOCFromList)=>{
                return facLOC.facilityLevelOfCareId == facLOCFromList.facilityLevelOfCareId
              })

              if(foundFacLOCFromList){
                return {
                  ...facLOC,
                  pdrRate: foundFacLOCFromList.pdrRate,
                }
              }else{
                throw new Error("Could not find facility level of care from newFacilityLevelsOfCareList Redux state")
              }
            })

            putEstimateBody = {
              vobId: vob.vobId!,
              clientId:
              newEstimate?.clientId,
              facilityId:
              newEstimate?.facilityId,
              advisorPatientId:
              newEstimate?.advisorPatientId,
              description:
              newEstimate?.description,
              quoteMethod: stringToQuoteMethod(selectedQuoteMethod),
              anticipatedAdmitDate: new Date(createEstimatorFormik.values.admissionDate),
              facilityLevelOfCare: updatedFacilityLevelOfCare,
              priorCare: newEstimate.priorCare,
              isPlanYearCrossover: newEstimate.isPlanYearCrossover,
              crossoverSelection:
              newEstimate?.crossoverSelection,
              isActive: newEstimate.isActive,
            };

            break;
          }

          case ['avglos', 'rlos'].includes(selectedQuoteMethod): {
            // Any quote method -> avlos/rlos

            const updatedFacilityLevelOfCare = newEstimate?.facilityLevelOfCare?.map((facLOC) => {

              const foundFacLOCFromList = newFacilityLevelsOfCareList?.find((facLOCFromList)=>{
                return facLOC.facilityLevelOfCareId == facLOCFromList.facilityLevelOfCareId
              })

              if(foundFacLOCFromList){
                return {
                  ...facLOC,
                  losDays: selectedQuoteMethod === 'avglos' ? foundFacLOCFromList.losDays : foundFacLOCFromList.recommendedLos,
                  pdrRate: foundFacLOCFromList.pdrRate,
                }
              }else{
                throw new Error("Could not find facility level of care from newFacilityLevelsOfCareList Redux state")
              }
            })

            putEstimateBody = {
              vobId: vob.vobId!,
              clientId:
              newEstimate?.clientId,
              facilityId:
              newEstimate?.facilityId,
              advisorPatientId:
              newEstimate?.advisorPatientId,
              description:
              newEstimate?.description,
              quoteMethod: stringToQuoteMethod(selectedQuoteMethod),
              anticipatedAdmitDate: new Date(createEstimatorFormik.values.admissionDate),
              facilityLevelOfCare: updatedFacilityLevelOfCare,
              priorCare: newEstimate.priorCare,
              isPlanYearCrossover: newEstimate.isPlanYearCrossover,
              crossoverSelection:
              newEstimate?.crossoverSelection,
              isActive: newEstimate.isActive,
            };

            break;
          }

          default:

        }

        if(putEstimateBody){
          dispatch(callNewEstimatePut({
            estimateId: newEstimate.estimateId,
            estimate: putEstimateBody
          }))
        }
      }
    }catch(e){
      console.error(e)
      dispatch(showErrorStatus("Error updating quote method and Level of Care"));
    }

  }

	// const handleServiceLevelChange = (e: Event) => {
	// 	const selectedServiceLevel = (e.target as HTMLInputElement).value;
	// 	let estimatorStateCopy = { ...estimatorState };
	// 	estimatorStateCopy.serviceLevel = selectedServiceLevel;
	// 	dispatch(setEstimator(estimatorStateCopy));
	// }

	const handleAdmissionDateChange = (date: Date) => {
		const selectedAdmissionDate = new Date(date);
		let estimatorStateCopy = Utils.deepClone(estimatorState);
    estimatorStateCopy.admissionDate = selectedAdmissionDate.toDateString();
    dispatch(setEstimator(estimatorStateCopy));
	}

	const clearSavedLOC = (): EstimatorBody => {
		let estimatorStateCopy = Utils.deepClone(estimatorState);
		estimatorStateCopy.selectedLevelsOfCare = [];
		estimatorStateCopy.hasPriorCare = false;
		estimatorStateCopy.hasFinancialAssistance = false;
		return estimatorStateCopy;
  };

  const forceValidateOnload = () => {
    setTimeout(() => {
      createEstimatorFormik.validateForm() // unlock the IOC
    }, 500);
  }

  const clearAAState = () => {
	dispatch(clearPatient());
	dispatch(clearVOB());
	dispatch(clearEstimator());
	dispatch(clearFacilityPayers());
	dispatch(clearRiskAssessment());
	dispatch(clearNewEstimate())
	handleEstimatorTabClick1(0);
  }

  const handlePaymentModalCancel = () => {
	setOpenPaymentModal(false);
	dispatch(clearPaymentURLParams());
  }

  const dontShowNonAdjustedSummary = isClientConfiguredForNewEstimate && newEstimate && (newEstimate.isPlanYearCrossover || newEstimate.priorCareSummary)

  const admissionDate = (
      <form>
        <Grid container spacing={2} direction="row">
          <Grid item xs={6} direction="column">
            <DatePicker
                error={
                    Utils.isValidDate(createEstimatorFormik.values.admissionDate) &&
                    createEstimatorFormik.touched["admissionDate"] &&
                    createEstimatorFormik.errors["admissionDate"]
                }
                label="Anticipated Admission Date"
				value={
					// estimatorState.admissionDate
					// 	? moment.utc(estimatorState.admissionDate).format('YYYY-MM-DD')
						new Date(createEstimatorFormik.values.admissionDate)	
				}
                onChange={(date: Date) => {
                  if (Utils.isValidDate(date)) {
					if(!isClientConfiguredForNewEstimate){
						handleAdmissionDateChange(date);
					}
                    createEstimatorFormik.setFieldValue("admissionDate", date);
                  }
                }}
                minDate={Utils.getMinDate()}
                maxDate={Utils.getMaxDate()}
            />
          </Grid>
        </Grid>
      </form>
  )

  const quoteMethodValueAsString = typeof createEstimatorFormik.values.quoteMethod === 'string' ? createEstimatorFormik.values.quoteMethod : quoteMethodToString(createEstimatorFormik.values.quoteMethod as QuoteMethod);
  const quoteMethodValueAsEnum = typeof createEstimatorFormik.values.quoteMethod === 'string' ? stringToQuoteMethod(createEstimatorFormik.values.quoteMethod) : createEstimatorFormik.values.quoteMethod;

	return (
		<>
			{isLoadingClients ? (
				<LoadingOverlay />
			) : (
				<>
					<Grid container justifyContent="space-between" direction="row" spacing={2}>
						<Grid item direction="column" xs={8}>
							{/* Estimator Details */}
							<Paper>
								<Box
									style={{
										padding: "1.0em",
										borderBottom: "1px solid #cccccc",
										marginBottom: "1.5em"
									}}
								>
									<Grid container justifyContent="space-between" direction="row">
										<Grid
											item
											justifyContent="center"
											direction="column"
											style={{
												display: "flex",
												alignItems: "center",
												flexWrap: "wrap",
												flexDirection: "row",
												paddingBottom: "12px"
											}}
										>
											<Typography
												variant="subtitle2"
												style={{
													display: "flex",
													alignItems: "center",
													paddingBottom: "0px",
													marginBottom: "0px"
												}}
											>
												<SettingsIcon sx={{ marginRight: "0.25em" }} /> Estimator Details
											</Typography>
											<PatientSearchArea
												clearAAState={clearAAState}
												vob={vob}
												updatePatientFormik={() => {}}
												updateVOBOnPatientSelection={() => {}}
												updateVobFormik={() => {}}
												handleEstimatorTabClick={clickEstimatorTab}
											/>
										</Grid>
										<Grid
											item
											justifyContent="center"
											direction="column"
											style={{
												display: "flex",
												alignItems: "center",
												flexWrap: "wrap",
											}}
										></Grid>
									</Grid>
									<form>
										<Grid container spacing={2} direction="row">
											<Grid item xs={6}>
												<TextField
													select
													label="Client"
													name="client"
													disabled={true}
													value={estimatorState.client?.clientId? estimatorState.client.clientId : createEstimatorFormik.values.client}
													onChange={(e: Event) => {
														createEstimatorFormik.handleChange(e);
														handleClientChange(e);
													}}
													onBlur={createEstimatorFormik.handleBlur}
													error={Boolean(createEstimatorFormik.touched.client && createEstimatorFormik.errors.client)}
												>
												{clients &&
													[...clients]
														?.sort((a, b) => (a.clientName > b.clientName ? 1 : -1))
														.map((client: ClientStatusCardViewModel) => (
															<MenuItem key={client.clientId} value={client.clientId}>
																{client.clientName}
															</MenuItem>
														))
												}
												</TextField>
											</Grid>
											<Grid item xs={6}>
												<TextField
													select
													label="Facility"
													name="facility"
													value={estimatorState.facility?.facilityId? estimatorState.facility.facilityId : createEstimatorFormik.values.facility}
													loading={isLoadingFacilities}
													onChange={(e: Event) => {
														createEstimatorFormik.handleChange(e);
														handleFacilityChange(e)
													}}
													onBlur={createEstimatorFormik.handleBlur}
													error={ Boolean(createEstimatorFormik.touched.facility && createEstimatorFormik.errors.facility) }
												>
													{facilities
														.flat()
														.map((facility: ImplementationFacility) => (
															<MenuItem
																key={facility.facilityId}
																value={facility.facilityId}
															>
																{facility.facilityName}
															</MenuItem>
														))}
												</TextField>
											</Grid>
											<Grid item xs={6}>
												<TextField
													select
													label="Quote Method"
													name="quoteMethod"
													value={quoteMethodValueAsString}
													onChange={(e: Event) => {
                            createEstimatorFormik.handleChange(e);
                            if(isClientConfiguredForNewEstimate){
                              handleNewEstimateQuoteMethodChange(e)
                            }else{
                              handleQuoteMethodChange(e);
                            }
													}}
													onBlur={createEstimatorFormik.handleBlur}
													error={Boolean(
															createEstimatorFormik.touched.quoteMethod &&
															createEstimatorFormik.errors.quoteMethod
														)
													}
												>
													<MenuItem value="avglos">Average Length of Stay</MenuItem>
													<MenuItem value="rlos">Recommended Length of Stay</MenuItem>
													<MenuItem value="manual">Manual</MenuItem>
													<MenuItem value="sca">Single Case Agreement</MenuItem>
												</TextField>
											</Grid>
											{/* <Grid item xs={6}>
												<TextField
													select
													label="Service Level"
													name="serviceLevel"
													value={estimatorState.serviceLevel? estimatorState.serviceLevel : createEstimatorFormik.values.serviceLevel}
													onChange={(e: Event) => {
														createEstimatorFormik.handleChange(e);
														handleServiceLevelChange(e);
													}}
													onBlur={createEstimatorFormik.handleBlur}

													error={ Boolean(
															createEstimatorFormik.touched.serviceLevel &&
															createEstimatorFormik.errors.serviceLevel
														)
													}
												>
													<MenuItem value="inpatient">Inpatient</MenuItem>
													<MenuItem value="outpatient">Outpatient</MenuItem>
													<MenuItem value="inpatientppp">Inpatient PPP</MenuItem>
												</TextField>
											</Grid> */}
										</Grid>
									</form>
								</Box>
							</Paper>

							 {/*Levels of Care */}

              <Paper>
                <Box
                    style={{
                      padding: "1.0em",
                      borderBottom: "1px solid #cccccc",
                      marginBottom: "1.5em",
                    }}
                >
                  <Grid container justifyContent="space-between" direction="row">
                    <Grid
                        item
                        direction="column"
                        xs={12}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          paddingBottom: "0.5em"
                        }}
                    >
                      <Typography
                          variant="subtitle2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                      >
                        <PermContactCalendarIcon sx={{ marginRight: "0.25em" }} /> Levels of Care
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={6} direction="column">
                      {admissionDate && (admissionDate)}
                    </Grid>
					{isClientConfiguredForNewEstimate ? (
						<NewLOCSummary
							handleLocModalOpen={() => {
								setIsLOCModalOpen(true)
							}}
    						enableLOSbutton={true}
    						selectedLevelsOfCare={newEstimate?.facilityLevelOfCare || []}
							quoteMethod={quoteMethodValueAsEnum}
							displayValueRed={doesLOCSummaryValNeedUpdating}
						/>
					) : (
						<LOCSummary
							handleLocModalOpen={handleLocModalOpen}
							enableLOSbutton={enableLOSbutton}
							facilities={facilities}
							selectedLevelsOfCare={estimatorState.selectedLevelsOfCare}
							quoteMethod={estimatorState.quoteMethod}
							vob={{
							  inNetwork: !!vob?.payer?.inNetwork,
							  isSelfPay: !!vob?.selfPay
							}}
						/>
					)}
                  </Grid>
                </Box>
              </Paper>

              {/* Prior Care */}
                {isClientConfiguredForNewEstimate && (newEstimate?.summary || newEstimate?.crossOverSummary) ? 
                    <NewEstPriorCareSummary /> :
                    <EstPriorCareSummary facilities={facilities} facilityId={createEstimatorFormik.values.facility! as number} />
                }

              {/* Financial Assistance */}
							{isClientConfiguredForNewEstimate ? (
                  <EstFinAssistanceSummaryNew />
              ) : (
                  <EstFinAssistanceSummary />
              )}

						</Grid>
						<Grid item direction="column" xs={4}>
							<Box border={1} borderColor="primary.main" p={2}
								style={{
									backgroundColor: "white",
									marginBottom: "1.5em",
								}}>
								<Typography variant="subtitle2">
									Financial Summary
								</Typography>
								<SidebarFinancialSummary />
							</Box>

							<SidebarPlanYearCrossover />

							{dontShowNonAdjustedSummary ? null :
								<SidebarTotalEstimatedPfr />
							}

							<SidebarPriorCareAdjustment />

              {isClientConfiguredForNewEstimate ? 
                  <SidebarFinancialAssistanceNew /> :
                  <SidebarFinancialAssistance /> 
              }

							<AlertBoxes alerts={payerAlerts}/>
                            <AlertBoxes alerts={employerAlerts}/>
                            <AlertBoxes alerts={groupAlerts}/>
                            <AlertBoxes alerts={prefixAlerts}/>

						</Grid>
					</Grid>

          {isLOCModalOpen && (
				<EstLevelOfCareModal
					open={isLOCModalOpen}
					clientId={createEstimatorFormik.values.client}
					facilityId={createEstimatorFormik.values.facility}
					facilityName={facilities?.flat().find((el) => el.facilityId === createEstimatorFormik.values.facility)?.facilityName}
					quoteMethod={createEstimatorFormik.values.quoteMethod}
					admissionDate={new Date(createEstimatorFormik.values.admissionDate)}
					newFacilityLevelsOfCareList={newFacilityLevelsOfCareList}
					handleEstimatorLevelOfCareModalClose={handleEstimatorLevelOfCareModalClose}
				/>
			)}

					{isClientConfiguredForNewEstimate ?
							<NewEstFooter /> :
							<EstFooter />
					}
					<TakePaymentModal paymentData={paymentData} open={openPaymentModal} handleModalCancel={handlePaymentModalCancel} />
				</>
			)}
		</>
	);

}

export default Estimator;
