import { LevelOfCare, CrmPrefilledLoC } from "../../admin-configuration/models/level-of-care";
import { RiskAssessmentForm } from "./risk-assessment";
import {
	FacilityLevelOfCare
} from '@finpay/estimation-types';

export interface PayorLocRateLos {
	clientFacilityId?: number;
	facilityPayorLocRateLosId?: number;
	facilityLevelOfCareId?: number;
	facilityPayorPlanId?: number;
	effectiveDate?: string;
	isCovered?: boolean | undefined;
	pdrRate?: number;
	losDays?: number;
	entryType?: string;
	cfgPayorPlanId?: number
}

export interface FacilityLocListItem {
  facilityId: number;
  facilityName: string;
	facilityLevelOfCareId: number,
	selected: boolean;
}

export interface FacilityLocRlos {
	facilityLocRlosId?: number,
	clientFacilityId?: number,
	facilityLevelOfCareId?: number | string,
	losDays?: number
}

export interface RatesLos {
	pdrRate?: number | null | undefined,
	losDays?: number | null | undefined
}

export interface EstimateRequestConfig {
	paramId: number;
	estimationId: number;
}

export interface EstLevelOfCare {
	facilityLevelOfCareId?: number,
	facilityLevelOfCareIdArray?: number[],
	clientFacilityId: number,
	cfgLevelOfCareId: number,
	facilityLevelOfCareCode?: string | null | undefined,
	facilityLevelOfCareName: string | null | undefined,
	thirdPartyAverageInnLos?: number,
	thirdPartyAverageInnPdr?: number,
	thirdPartyAverageOnnLos?: number,
	thirdPartyAverageOnnPdr?: number,
	vobClassificationId?: number | null | undefined;
	isCovered?: boolean | undefined,
	isAddOnLos?: boolean,
	sortOrder?: number | undefined,
	perDiemOperatingCost?: number | undefined,
	minRevenueAmount?: number | undefined,
	minRevenueFlatRate?: boolean | undefined,
	facilityLocRlos?: FacilityLocRlos | null,
	facilityPayorLocRateLos?: PayorLocRateLos[] | PayorLocRateLos,
	preselected?: boolean,
	facilityLevelOfCareOverrideName?: string,
	checked?: boolean,
	levelOfCareName?: string,
	levelOfCareCode?: string,
	facilityName?: string
}

export interface EditEstLevelOfCare {
	facilityLevelOfCareId?: number,
	multiFacilityId?: string,
	clientFacilityId: number,
	facilityName?: string,
	cfgLevelOfCareId: number,
	cfgLocType?: number,
	facilityLevelOfCareCode?: string | null | undefined,
	facilityLevelOfCareName: string | null | undefined,
	thirdPartyAverageInnLos?: number,
	thirdPartyAverageInnPdr?: number,
	thirdPartyAverageOnnLos?: number,
	thirdPartyAverageOnnPdr?: number,
	vobClassificationId?: number | null | undefined;
	isCovered?: boolean | undefined,
	isAddOnLos?: boolean,
	sortOrder?: number | undefined,
	perDiemOperatingCost?: number | undefined,
	minRevenueAmount?: number | undefined,
	minRevenueFlatRate?: boolean | undefined,
	facilityLocRlos?: FacilityLocRlos | null,
	facilityPayorLocRateLos?: PayorLocRateLos,
	facilityLocCost?: EstLocCost,
	facilitySurcharge?: EstLocSurcharge,
	facilities?: FacilityLocListItem[],
	manualRatesLos?: RatesLos,
	scaRatesLos?: RatesLos,
	selectedRateLos?: RatesLos
}

export interface EstLocCost {
	serviceDate: string,
	fee: number
}

export interface EstLocSurcharge {
	serviceDate: string,
	feePct: number
}

export interface EstLevelOfCareServiceConfig {
	paramId: number,
	facilityId: number,
	planId: number,
	quoteMethod: string,
	masterListLevelsOfCare: LevelOfCare[]
}

export interface EstPriorLevelsOfCare {
	priorCareProvider: {
		name: string,
		value: string,
	},
	thirdPartyPayerStatus: {
		name: string,
		value: string,
	}
	facility: {
		facilityId: number | null,
		facilityName: string | undefined
	},
	priorThirdPartyLevelsOfCare: LevelOfCare[] | null | undefined,
	priorFacilityLevelsOfCare: EstLevelOfCare[],
  priorLevelsOfCare?: any, // not sure what shape this is but TS needs it.
}

export interface PriorCareSummary {
	masterListLoc: boolean,
	labelOne: string,
	labelTwo: string,
	valueOne: string,
	valueTwo: string,
	priorLevelsOfCare: LevelOfCare[] | EstLevelOfCare[] | null | undefined,
}

export interface NewPriorCareSummary extends Omit<PriorCareSummary, 'masterListLoc' | 'priorLevelsOfCare'>{
	priorLevelsOfCare: FacilityLevelOfCare[]
}

export interface PriorCarePFR {
	priorCarePFR: PFRSummary;
	adjustedCurrentPFR: PFRSummary;
}

export interface EstFinancialAssistance {
		isValid: boolean;
    inValidReason: string;
		houseHoldSize?: number,
		patientIncomeExpenses: {
			income?: number;
			expenses?: number;
			incomeSources: string[];
		};
		scholarshipName?: string;
		natlPovertyThreshold?: number,
		clientFplApprovalThreshold?: number,
		clientIncomeExpenseThreshold?: number,
		capacityToPayScore?: number;
		incomeVerificationScore?: number;
		disposableIncome?: number;
		retirementIncome?: number;
		businessIncome?: number;
		investmentIncome?: number;
		alimonyIncome?: number;
		avgWealthIncome?: number;
		qualifiesForAssistance: boolean;
    scholarshipPercentage?: number;
}

export interface EstimatorBody {
		pfrEstimateId?: number;
		isActive?: boolean;
		description?: string;
		selectedPFRName?: string;
		totalEstimatedPfr?: number;
		priorCareAdjustmentPfr?: number;
		crossoverAdjustmentPfr?: number;
		financialAssistanceAdjustmentPfr?: number;
		client: {
			clientId?: number;
			clientName?: string;
		},
		facility: {
			facilityId?: number;
			facilityName?: string;
		},
		serviceLevel?: string;
		quoteMethod?: string;
		admissionDate?: string;
		selectedLevelsOfCareFacilityType?: string;
		selectedLevelsOfCare?: EditEstLevelOfCare[];
		hasPriorCare?: boolean;
		hasFinancialAssistance?: boolean;
		priorCare?: EstPriorLevelsOfCare;
		financialAssistance?: EstFinancialAssistance;
		planYearCrossover?: {
			hasPlanYearCrossover?: boolean;
			crossoverDays?: number;
			usingPlanYearCrossoverPfr?: boolean;
			planYearCrossoverConfirmed?: boolean;
		}
		finPay: {
			patientId?: number;
			patientEncounterId?: number;
			sentDateTime?: string;
			isExistingIOC?: boolean;
			pfrEstimateId?: number;
		}
		riskAssessmentForm?: RiskAssessmentForm | undefined;
		crmPrefilledLoC?: CrmPrefilledLoC[];
}

export interface PfrEstimator {
	pfrEstimateId?: number;
	advisorPatientId: number;
	vobId: number;
	estimateBody: EstimatorBody;
	ormVersion: number;
	createUserId: string;
	createDt: string;
	lastUpdateUserId?: string;
	lastUpdateDt?: string;
}

export interface SaveEstimatorPostBody {
	paramId?: number;
	estimateId?: number;
	vobId: number;
	advisorPatientId: number;
	fpClientId?: number | undefined;
	fpClientFacilityId?: number | undefined;
	estimateBody: EstimatorBody;
}

export interface FetchedEstimate {
	ormVersion?: number,
	createUserId?: string,
	createDt?: string,
	pfrEstimateId?: number,
	vobId?: number,
	advisorPatientId?: number,
	estimateBody?: EstimatorBody,
	lastUpdateUserId?: string,
	lastUpdateDt?: string
}

export interface PFRSummary {
	lengthOfStay: number;
	lengthOfStayCovered?: number;
	lengthOfStayUnCovered?: number;
	netPFR: number;
	subtotalPFR: number,
	totalSurcharges: number;
	totalPFR: number;
	scholarshipAdjustedSubtotalPFR: number; 
    scholarshipAdjustedSurcharge: number;
    scholarshipAdjustedTotalPFR: number;
	coveredCharges: number;
	unCoveredCharges: number;
	coveredSvcsPfr: number;
	patientTotalCoPays: number;
	patientTotalCoInsurance: number;
	deductible: number;
	remainingDeductible: number;
	deductibleSpent: number;
	oopMaxFromVob: number,
    remainingOopMaxFromVob: number,
    remainingOopMaxAfterServices: number
	includeOOPmax: boolean;
}

export const emptyPFRSummary = {
	lengthOfStay: 0,
	lengthOfStayCovered: 0,
	lengthOfStayUnCovered: 0,
	netPFR: 0,
	subtotalPFR: 0,
	totalSurcharges: 0,
	totalPFR: 0,
	scholarshipAdjustedSubtotalPFR: 0,
    scholarshipAdjustedSurcharge: 0,
    scholarshipAdjustedTotalPFR: 0,
	coveredCharges: 0,
	unCoveredCharges: 0,
	coveredSvcsPfr: 0,
	patientTotalCoPays: 0,
	patientTotalCoInsurance: 0,
	deductible: 0,
	remainingDeductible: 0,
	deductibleSpent: 0,
	oopMaxFromVob: 0,
	remainingOopMaxFromVob: 0,
	remainingOopMaxAfterServices: 0,
	includeOOPmax: false,
}

export interface SummaryOopDeductible {
	deductibleSpent: number,
	remainingDeductible: number,
	remainingOopMax: number,
}

export interface CrossoverPolicies {
	currentPolicyFinancialSummary: PFRSummary,
	nextPolicyFinancialSummary: PFRSummary
}

export enum apiStatus {
  none,
  success,
  error,
}

export interface Estimate {
	pfrEstimateId?: number,
	isActive: boolean,
	description?: string,
	selectedPFRName?: string,
	totalEstimatedPfr?: number,
	priorCareAdjustmentPfr?: number,
	crossoverAdjustmentPfr?: number,
	financialAssistanceAdjustmentPfr?: number,
	client: {
		clientId?: number,
		clientName?: string,
	},
	facility: {
		facilityId?: number,
		facilityName?: string,
	},
	serviceLevel: string,
	quoteMethod: string,
	admissionDate: string,
	selectedLevelsOfCareFacilityType: string;
	selectedLevelsOfCare: EditEstLevelOfCare[],
	hasPriorCare: boolean,
	hasFinancialAssistance: boolean,
	priorCare: EstPriorLevelsOfCare,
	financialAssistance: EstFinancialAssistance,
	planYearCrossover?: {
		hasPlanYearCrossover?: boolean,
		crossoverDays: number,
		usingPlanYearCrossoverPfr?: boolean,
		planYearCrossoverConfirmed?: boolean,
	}
	finPay: {
		patientId?: number,
		patientEncounterId?: number,
		sentDateTime?: string,
		isExistingIOC?: boolean,
		pfrEstimateId?: number
	}
	riskAssessmentForm: RiskAssessmentForm | undefined,
	crmPrefilledLoC?: CrmPrefilledLoC[]
}

export const emptyEstimate: Estimate = {
	pfrEstimateId: undefined,
	isActive: true,
	description: "",
	selectedPFRName: "Total Estimated PFR",
	totalEstimatedPfr: undefined,
	priorCareAdjustmentPfr: 0,
	crossoverAdjustmentPfr: 0,
	financialAssistanceAdjustmentPfr: 0,
	client: {
		clientId: undefined,
		clientName: "",
	},
	facility: {
		facilityId: undefined,
		facilityName: "",
	},
	serviceLevel: "",
	quoteMethod: "",
	admissionDate: "",
	selectedLevelsOfCareFacilityType: "",
	selectedLevelsOfCare: [],
	hasPriorCare: false,
	hasFinancialAssistance: false,
	priorCare: {
		priorCareProvider: {
			name: "",
			value: "",
		},
		thirdPartyPayerStatus: {
			name: "",
			value: "",
		},
		facility: {
			facilityId: null,
			facilityName: ""
		},
		priorThirdPartyLevelsOfCare: [],
		priorFacilityLevelsOfCare: [],
	},
	financialAssistance: {
		isValid: false,
		inValidReason: "",
		houseHoldSize: undefined,
		patientIncomeExpenses: {
			income: undefined,
			expenses: undefined,
			incomeSources:[]
		},
		natlPovertyThreshold: 0.00,
		clientFplApprovalThreshold: 0,
		capacityToPayScore: 0.00,
		incomeVerificationScore: 0.00,
		disposableIncome: 0.00,
		retirementIncome: 0.00,
		businessIncome: 0.00,
		investmentIncome: 0.00,
		alimonyIncome: 0.00,
		avgWealthIncome: 0.00,
		qualifiesForAssistance: false,
		scholarshipPercentage: 0.00,
	},
	planYearCrossover: {
		hasPlanYearCrossover: false,
		crossoverDays: 0,
		usingPlanYearCrossoverPfr: false,
		planYearCrossoverConfirmed: false,
	},
	finPay: {
		patientId: undefined,
		patientEncounterId: undefined,
		sentDateTime: undefined
	},
	riskAssessmentForm: undefined,
	crmPrefilledLoC: []
}

export interface MaxDaysCoPays {
	vobClassificationId: number;
	losTotal: number;
	maxDays: number | null;
	coPayAmt: number;
};